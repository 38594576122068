import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import hinasoraimg from './hinasoralogoicon.png';
import hinatatosoraimg from './hinatatosora.png';
import hinafesimg from './hinafes.jpg';
import chiraminimg from './chiramin.jpg';
import andmoreimg from './andmore.jpg';

const useStyles = makeStyles({
  header: {
    position:'relative',
    background:'linear-gradient(170deg, #A9E2FF 0%, #FFB899 100%) no-repeat 50% 50% / 100% 100%',
    boxShadow: '0px 2px 2px 0px rgba(0,0,0,0.4) ',
    textAlign: 'center',
    padding:10,
  },
  headerimg: {
    position:'absolute',
    top:10,
    left:10,
    height:'8vh',
    opacity: '0.8',
    filter: 'drop-shadow(-1px -1px 1px rgba(0,0,0,0.4))',
    //    boxShadow: '2px 2px 2px 0px rgba(0,0,0,0.4) inset',
//    backgroundColor: 'rgba(255, 255, 220, 0.90)',
  },
  hinatatosoraimg: {
    position:'absolute',
    bottom:'5%',
    right:'5%',
    height:'30%',
    opacity: '0.1',
  },
  headertitle: {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    marginTop: 'auto',
    marginBottom: 'auto',
//    backgroundColor: 'rgba(220, 255, 255, 0.90)',
  },
  httext: {
    fontFamily:'"WorstveldRg"',
//    fontWeight:'bold',
    color:'rgba(255, 255, 255, 0.90)',
  },

  cards: {
    marginTop: 50,
    marginBottom: 100,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
  },
  card: {
    margin  :  20,
    maxWidth: 345,
  },
  warn: {
    margin  :  10,
    padding :  10,
    maxWidth: 345,
    textAlign:'center',
    border  : '1px solid #FCB',
    borderRadius: '5px',
    backgroundColor:'#FFF6F0',
  },

  footer: {
    padding  :  3,
    textAlign: 'center',
    width: '100vw',
    position: 'fixed',
//    backgroundColor: 'rgba(0, 0, 0, 0.90)',
    color          : 'rgba(255, 255, 255, 0.90)',
    boxShadow: '0px -4px 4px 0px rgba(0,0,0,0.4) ',
    background:'linear-gradient(170deg, #FFB899EE 0%, #A9E2FFEE 100%) no-repeat 50% 50% / 100% 100%',
    bottom: 0,
    left: 0,
    zIndex: 1000,
    fontFamily:'"WorstveldRg"',
  },
  btn : {
    textTransform: 'none',
    textDecoration:'underline',
  }
});

function FesCard() {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardActionArea>
        <CardMedia
          component="img"
          alt="#ひなまつり"
          image={hinafesimg}
          title="#ひなまつり"
        />
        <CardContent>
          <Typography gutterBottom variant="subtitle2" component="h2">
          Instagram 猫のお祭りタグガイド
          </Typography>
          <Typography gutterBottom variant="h6" component="h2">
          #ひな&#x1f43e;まつり
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
          Instagram の猫さんアカウント向けに、猫のお祭り、同好会、合言葉、チャリティ企画、コンテスト
          などのハッシュタグを紹介します。
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button size="small" className={classes.btn} href="https://fes.hinasora.com/">
        Welcome to #ひな&#x1f43e;まつり
        </Button>
      </CardActions>
    </Card>
  );
}

function ChiraminCard() {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardActionArea>
        <CardMedia
          component="img"
          alt="Chiramin polar bear"
          image={chiraminimg}
          title="Chiramin polar bear"
        />
        <CardContent>
          <Typography gutterBottom variant="subtitle2" component="h2">
          Chiramin polar bear
          </Typography>
          <Typography gutterBottom variant="h6" component="h2">
          ちらみん。
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
          ひなたデザインのオリジナルキャラ、ちらみん。
          LINEスタンプの売上をチャリティーに使うつもりが、友人・同僚・親戚に配りすぎて大赤字の問題児。
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button size="small" className={classes.btn} href="https://chiramin.hinasora.com/">
        Welcome to ちらみん。 
        </Button>
      </CardActions>
    </Card>
  );
}


function AndmoreCard() {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardActionArea>
        <CardMedia
          component="img"
          alt="and more..."
          image={andmoreimg}
          title="and more..."
        />
        <CardContent>
          <Typography gutterBottom variant="h6" component="h2">
          今はこれしかないけれど
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
          猫さんインスタを便利に楽しく補強するコンテンツを追加していきたいです。
          当面は「#ひな🐾まつり」の安定運用を頑張ります💦
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
      <Typography variant="body2" color="textSecondary" component="p">
      @hinata_pocky
      </Typography>
        <Button size="small" className={classes.btn} href="instagram://user?username=hinata_pocky">
          アプリ
        </Button>
        ／
        <Button size="small" className={classes.btn} href="https://www.instagram.com/hinata_pocky/" target="_blank" rel="noopener noreferrer">
          ブラウザ
        </Button>
      </CardActions>
    </Card>
  );
}




function App() {
  const classes = useStyles();

  return (
    <div className="App">
      <div className={classes.header}>
        <div className={classes.headertitle}>
        <Typography gutterBottom variant="h4" component="h2" className={classes.httext}>hinasora.com</Typography>
        <Typography gutterBottom variant="h5" component="h2" className={classes.httext}>things</Typography>
        <Typography gutterBottom variant="h5" component="h2" className={classes.httext}>of the cats ,</Typography>
        <Typography gutterBottom variant="h5" component="h2" className={classes.httext}>by the cats ,</Typography>
        <Typography gutterBottom variant="h5" component="h2" className={classes.httext}>for the cats .</Typography>
        <img src={hinasoraimg} alt="hinasora.com"  className={classes.headerimg} />
        <img src={hinatatosoraimg} alt="hinata & sora"  className={classes.hinatatosoraimg} />
        </div>
      </div>

      <div className={classes.cards}>
      <div className={classes.warn}>
      <Typography gutterBottom variant="subtitle2" color="error">プロフのリンクからいらっしゃった方へ</Typography>
      <Typography gutterBottom variant="caption" component="p" noWrap>サイト内のリンクがうまく機能しない場合</Typography>
      <Typography gutterBottom variant="caption" component="p" noWrap>SafariやChromeで開きなおしてください</Typography>
      <Typography gutterBottom variant="caption" component="p" noWrap>ブラウザを起動して hinasora.com と入力</Typography>
      <Typography gutterBottom variant="caption" component="p" noWrap>お手数をおかけします・・・</Typography>
      </div>
      <FesCard />
      <ChiraminCard />
      <AndmoreCard />
      </div>
      <div className={classes.footer}>
      <Typography variant="h6" component="h2" className={classes.httext}>©2019 hinata@hinasora.com</Typography>
      </div>
    </div>
  );
}

export default App;
